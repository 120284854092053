window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function() {
    processInclude(require('./components/forms'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./homeAnniversary/home'));
    processInclude(require('./components/popups'));
});

require('bootstrap/js/src/modal.js');
require('base/components/spinner');
