'use strict';

const home = {};
const opener = '.newsletter, .newsletter-mobile',
    NEWSLETTER_OPTIN = '.newsletterOptinModal',
    newsletterComponents = require('../components/newsletter'),
    languageSelector = '#languages',
    carousel = '.items';

home.init = function() {
    $(opener).on('click', function() {
        $(NEWSLETTER_OPTIN).modal('show');
    });

    if($(NEWSLETTER_OPTIN).data('force-open') === true) {
        $(NEWSLETTER_OPTIN).modal('show');
    }
};

home.countrySelector = function() {
    $(languageSelector).on('change', function() {
        var url = $(this).val();
        window.location.href = url;
    });
};

home.manageCarousel = function() {
    $(carousel).find('.next').on('click', function(e) {
        e.preventDefault();
        var currentScroll = $(carousel).scrollLeft();
        var nextTarget = $($(this).attr('href'));
        if(typeof nextTarget.position() !== 'undefined') {
            $(carousel).animate({scrollLeft: nextTarget.position().left + currentScroll});
        }
    });

    $(carousel).find('.prev').on('click', function(e) {
        e.preventDefault();
        var currentScroll = $(carousel).scrollLeft();

        var prevTarget = $($(this).attr('href'));
        if(typeof prevTarget.position() !== 'undefined') {
            $(carousel).animate({scrollLeft: prevTarget.position().left + currentScroll});
        }
    });
};

home.manageCaptchaPopup = newsletterComponents.manageCaptchaPopup;
home.newsletterPopup = newsletterComponents.manageNewsletterPopupSubmit;

module.exports = home;
